<!-- eslint-disable -->
<template>
  <base-material-card
      icon="mdi-clipboard-text"
      title="Voir la liste des livraisons"
      class="px-5 py-3"
    >
    <template v-slot:heading>
      <v-icon @click="reloadItems">mdi-reload</v-icon>
    </template>
    <b-modal v-model="showDocModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Documents
    </template>
    <v-container class="py-0">
      <v-row>
        <v-col cols="12" md="12">
          <b-card title="Liste des documents renseignés">
            <v-row v-for="file in packageDetail.files" :key="file.file_name">
              <span style="padding:10px"><strong>{{file.doc_name}}:</strong><a target="_blank" style="margin-left:10px" :href="hostName+file.link">{{file.file_name}}</a></span>
            </v-row>
          </b-card>
        </v-col>
      </v-row>
    </v-container>
  </b-modal>
    <b-modal v-model="show2" @ok="getpaid()" title='CONFIRMATION DE PAIEMENT'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer le paiement</p>

    </b-modal>
    <b-modal v-model="show3" @ok="deletePackage" title='CONFIRMATION DE SUPPRESSION'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer la suppression</p>
    </b-modal>
    <!-- <b-modal v-model="show">
      <h3>{{infoshow.tracking_id}} :{{infoshow.content}}</h3><p>Clients name : {{infoshow.client_name}}</p><p>Phone Number : {{infoshow.client_phone}}</p>
      <p v-for="histori in infoshow.history" :key="histori.message">***{{moment(histori.creation_date).format('MM/DD/YYYY HH:mm')}} : {{histori.message}}</p>
    </b-modal> -->
    <b-modal v-model="show" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <div class="d-block text-center" style="background-color:white">
      <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
        <div v-if="packageDetail.provider != null && packageDetail.provider != undefined">
          <strong>Nom de l'entreprise: </strong>{{packageDetail.provider.name}}<br>
          <strong>N° Téléphone de l'entreprise: </strong>{{packageDetail.provider.phone}}<br>
        </div>
        <strong>Date de création: </strong>{{serialiseDate(packageDetail.creation_date)}}<br>
        <strong>Date de chargement: </strong>{{serialiseDate(packageDetail.pickup_date)}}<br>
        <strong>Date de livraison: </strong>{{serialiseDate(packageDetail.delivery_date)}}<br>
        <hr>
          <h4>Section d'historique</h4>
          <p v-for="histori in infoshow.history" :key="histori.message"><strong>{{moment(histori.creation_date).format('DD/MM/YYYY HH:mm')}}: </strong> {{histori.message}}</p>
        <hr>
        <h4>Section d'infomation de client</h4>
        <strong>Nom complet de l'expéditeur: </strong>{{packageDetail.client_name}}<br>
        <strong>N° Téléphone de l'expéditeur: </strong>{{packageDetail.client_phone}}<br>
        <strong>Nom complet de receveur: </strong>{{packageDetail.receiver_name}}<br>
        <strong>N° Téléphone de receveur: </strong>{{packageDetail.receiver_phone}}<br>
        <strong>Adresse de départ: </strong>{{packageDetail.from_wilaya}}, {{packageDetail.address1}}<br>
        <strong>Adresse d'arrivé: </strong>{{packageDetail.to_wilaya}}, {{packageDetail.address2}}<br>
        <strong>Contenu de colis: </strong>{{packageDetail.content}}<br>
        <strong>Poids de colis: </strong>{{packageDetail.weight}} TN<br>
        <strong>Frais de colis: </strong>{{packageDetail.fees}}<br>
        <strong>Prix Total: </strong>{{parseInt(packageDetail.price)}}<br>
        <hr>
          <h4>Section de chaffeur</h4>
          <span v-if="packageDetail.dirver != null">
            <strong>Nom: </strong>{{packageDetail.driver.name}}<br>
            <strong>N° Téléphone: </strong>{{packageDetail.driver.phone}}<br>
            <strong>Matricule: </strong>{{packageDetail.driver.matricule}}<br>
          </span>
          <span v-else>
            <strong>Nom: </strong>{{packageDetail.driver_name}}<br>
            <strong>N° Téléphone: </strong>{{packageDetail.driver_phone}}<br>
            <strong>Matricule: </strong>{{packageDetail.driver_matricule}}<br>
          </span>
      </div>
    </div>
    <b-button class="mt-3" style="color:white" block @click="hideModal">Fermer</b-button>
  </b-modal>
  <b-modal v-model="showChangable" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
      <div style="text-align:center;">
        <v-btn class="mr-3" style="color:white;background-color:#616161" @click="makeChanges('Created')">Created</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#FBC02D" @click="makeChanges('Confirmé')">Confirmé</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#0096FF" @click="makeChanges('Chargement')">Chargement</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#8E24AA" @click="makeChanges('EnRoute')">EnRoute</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#000080" @click="makeChanges('Arrivé')">Arrivé</v-btn>
      </div>
    <hr>
    <b-form >
      <!-- @submit.prevent="makeChanges" -->
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_name" label="Nom de receveur" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_phone" label="N° Téléphone de receveur" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <b-form-select required v-model="packageDetail.from_wilaya"><option :value="wilaya.name" v-for="wilaya in willayas" :key="wilaya.name">{{wilaya.name}}</option> </b-form-select>
                <v-text-field required label="Adresse de départ" class="purple-input" v-model="packageDetail.address1"/>
              </v-col>
              <v-col cols="12" md="6">
                <b-form-select required v-model="packageDetail.to_wilaya"><option :value="wilaya.name" v-for="wilaya in willayas" :key="wilaya.name">{{wilaya.name}}</option> </b-form-select>
                <v-text-field required label="Adresse de départ" class="purple-input" v-model="packageDetail.address2"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field required label="Marchandise" class="purple-input" v-model="packageDetail.content"/>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field required label="Poids" class="purple-input" v-model="packageDetail.weight"/>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field required label="Quantité" class="purple-input" v-model="packageDetail.quantity"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <!-- <v-text-field required label="Type de Véhicule" class="purple-input" :value="packageDetail.delivery_type"/> -->
                <b-form-select v-model="packageDetail.delivery_type" required>
                    <option v-for="vehicule in vehicules" :key="vehicule" :value="vehicule">{{vehicule}}</option>
                </b-form-select>
              </v-col>
              <v-col cols="12" md="6">
                <multiselect required v-model="packageDetail.selected_driver" tag-placeholder="Chauffeur" placeholder="Entrer le nom de chauffeur" label="name" track-by="name" :options="drivers" :multiple="false" :taggable="true"></multiselect>
                <!-- <b-form-select required v-model="packageDetail.selected_driver">
                  <option v-for="driver in drivers" :key="driver.did" :value="driver.did" :class="(driver.available===true || packageDetail.selected_driver===driver.did ? 'color-green' : (driver.available === false ? 'color-red': ''))">
                    <span v-if="driver.available==false">{{driver.name}} {{driver.phone}}</span>
                    <span v-else>{{driver.name}} {{driver.phone}}</span>
                  </option>
                </b-form-select> -->
              </v-col>
              <v-col v-if="packageDetail.selected_driver==-1" cols="12" md="4">
                <v-text-field required label="Nom de Chauffeur" class="purple-input" v-model="packageDetail.driver_name"/>
              </v-col>
              <v-col v-if="packageDetail.selected_driver==-1" cols="12" md="4">
                <v-text-field required label="N° Téléphone Chauffeur" class="purple-input" v-model="packageDetail.driver_phone"/>
              </v-col>
              <v-col v-if="packageDetail.selected_driver==-1" cols="12" md="4">
                <v-text-field required label="Matricule Chauffeur" class="purple-input" v-model="packageDetail.driver_matricule"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field required v-model="packageDetail.price" label="Prix de livraison" type="number" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field required v-model="packageDetail.fees" label="Frais de livraison" type="number" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="4">
                  <b-form-select style="margin-top:15px" v-model="packageDetail.payment_method" required>
                    <option v-for="method in ['Chèque','Espèce']" :key="method" :value="method">{{method}}</option>
                  </b-form-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-textarea
                required
                class="purple-input"
                label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                v-model="packageDetail.description"
              />
            </v-col>
        </div>
      </div>
    </b-form>
  </b-modal>

  <b-modal v-model="showChangable2" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
      <div style="text-align:center;">
        <v-btn class="mr-3" style="color:white;background-color:#616161" @click="makeChanges2('Created')">Created</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#FBC02D" @click="makeChanges2('Confirmé')">Confirmé</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#0096FF" @click="makeChanges2('Chargement')">Chargement</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#8E24AA" @click="makeChanges2('EnRoute')">EnRoute</v-btn>
        <v-btn v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" class="mr-3" style="color:white;background-color:#000080" @click="makeChanges2('Arrivé')">Arrivé</v-btn>
      </div>
    <hr>
    <b-form >
      <!-- @submit.prevent="makeChanges" -->
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_name" label="Nom de receveur" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_phone" label="N° Téléphone de receveur" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field disabled required label="N° de colis" class="purple-input" v-model="packageDetail.colis_numbers"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field disabled required label="Quantité" class="purple-input" v-model="packageDetail.quantity"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field disabled required label="Poids" class="purple-input" v-model="packageDetail.weight"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <!-- <v-text-field required label="Type de Véhicule" class="purple-input" :value="packageDetail.delivery_type"/> -->
                <b-form-select v-model="packageDetail.delivery_type" required>
                    <option v-for="vehicule in vehicules" :key="vehicule" :value="vehicule">{{vehicule}}</option>
                </b-form-select>
              </v-col>
              <v-col cols="12" md="6">
                <b-form-select required v-model="packageDetail.selected_driver">
                  <option v-for="driver in drivers2" :key="driver.did" :value="driver.did" :class="(driver.available===true || packageDetail.selected_driver===driver.did ? 'color-green' : (driver.available === false ? 'color-red': ''))">
                    <span v-if="driver.available==false">{{driver.name}} {{driver.phone}}</span>
                    <span v-else>{{driver.name}} {{driver.phone}}</span>
                  </option>
                </b-form-select>
              </v-col>
              <v-col v-if="packageDetail.selected_driver.did==-1" cols="12" md="4">
                <v-text-field required label="Nom de Chauffeur" class="purple-input" v-model="packageDetail.driver_name"/>
              </v-col>
              <v-col v-if="packageDetail.selected_driver.did==-1" cols="12" md="4">
                <v-text-field required label="N° Téléphone Chauffeur" class="purple-input" v-model="packageDetail.driver_phone"/>
              </v-col>
              <v-col v-if="packageDetail.selected_driver.did==-1" cols="12" md="4">
                <v-text-field required label="Matricule Chauffeur" class="purple-input" v-model="packageDetail.driver_matricule"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.price" label="Prix de livraison" type="number" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.fees" label="Frais de livraison" type="number" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-textarea
                required
                class="purple-input"
                label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                v-model="packageDetail.description"
              />
            </v-col>
        </div>
        <div v-if="packageDetail != undefined && packageDetail != null" style="height:200px;margin-top:30px;overflow-y:auto,overflow-x:hidden">
            <label><strong>Liste des colis sélectionné:</strong></label>
            <v-row v-for="colis in packageDetail.colis" :key="colis.tracking_id">
              <v-col cols="12" md="4">
                <label><strong>Tracking ID:</strong> {{colis.tracking_id}} </label>
              </v-col>
              <v-col cols="12" md="4">
                <label><strong>Marchendise:</strong> {{colis.content}} </label>
              </v-col>
              <v-col cols="12" md="4">
                <label><strong>Quantité:</strong> {{colis.quantity}} </label>
              </v-col>
            </v-row>
          </div>
      </div>
    </b-form>
  </b-modal>


    <label><strong>Liste de logistique</strong></label>
    <vue-good-table
    :isLoading.sync="isLoading"
      :columns="columns"
      :rows="rows"
      @on-column-filter="filtering"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:5}"
      @on-row-click="RowClick">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <v-btn id="status-btn" v-if="props.row.status == 'Created'" depressed small color="#616161">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Confirmé'" depressed small color="#FBC02D">{{props.row.status}}</v-btn>
          <v-icon v-if="props.row.status == 'Created'" id="history-btn" style="margin-left:10px;color:red">mdi-close-box</v-icon>
          <v-icon id="doc-btn" style="color:blue;margin-left:10px;">mdi-file-document</v-icon>
        </span>
        <span v-else-if="props.column.field == 'provider_name'">
          {{props.row.provider.name}}
        </span>
        <span v-else-if="props.column.field == 'tracking_id'">
          <v-icon id='print-btn' style="margin-right:10px" color="primary">mdi-printer</v-icon>
          <v-btn id="tracking-id-btn" v-b-tooltip.hover :title="copyMessage" depressed small color="#37474F">{{props.row.tracking_id}}</v-btn>
          <v-icon v-b-tooltip.hover title="Livraison à domicile" v-if="props.row.delivery_type=='Home'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon>
          <v-icon v-b-tooltip.hover title="Livraison gratuit" v-if="props.row.free_delivered==true" style="margin-left:10px;margin-right:10px;" color="primary">mdi-currency-usd-off</v-icon>
          <!-- <v-icon v-if="props.row.delivery_type=='Stop Desk'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon> -->
          <v-btn id="pay-btn" v-if="props.row.status == 'Livrée' && props.row.posttoprovider == false && props.row.free_delivered == false && props.row.price != 0" class="float-right" depressed small color="red">NOT PAID</v-btn>
          <v-btn v-if="props.row.status == 'Livrée' && props.row.posttoprovider == true && props.row.free_delivered == false && props.row.price != 0" depressed small color="green" class="float-right">GET PAID</v-btn>
          <!-- <v-btn v-if="props.row. == 'Livrée' && props.row.posttoprovider == true" depressed small color="green">GET PAID</v-btn> -->
        </span>
        <span v-else-if="props.column.field == 'price'">
          <span>{{props.row.price}}</span>
          <v-icon v-if="props.row.changable == true" id="changable-btn" v-b-tooltip.hover title="Cliquer pour changer" style="margin-left:10px;margin-right:10px;" color="primary">mdi-cog</v-icon>
          <!-- <b-form-input v-else id="changable-input" type="number" v-model="props.row.price" @keydown.native="makeChanges"></b-form-input> -->
        </span>
        <span v-else>{{props.row[props.column.field]}}</span>
      </template>
    </vue-good-table>

    <label><strong>Liste de express</strong></label>

    <vue-good-table
    :isLoading.sync="isLoading"
      :columns="columns2"
      :rows="rows2"
      @on-column-filter="filtering"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:5}"
      @on-row-click="RowClick2">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <v-btn id="status-btn2" v-if="props.row.status == 'Created'" depressed small color="#616161">{{props.row.status}}</v-btn>
          <v-btn id="status-btn2" v-if="props.row.status == 'Confirmé'" depressed small color="#FBC02D">{{props.row.status}}</v-btn>
          <v-icon v-if="props.row.status == 'Created'" id="history-btn" style="margin-left:10px;color:red">mdi-close-box</v-icon>
        </span>
        <span v-else-if="props.column.field == 'tracking_id'">
          <v-icon id='print-btn' style="margin-right:10px" color="primary">mdi-printer</v-icon>
          <v-btn id="tracking-id-btn" v-b-tooltip.hover :title="copyMessage" depressed small color="#37474F">{{props.row.tracking_id}}</v-btn>
          <v-icon v-b-tooltip.hover title="Livraison à domicile" v-if="props.row.delivery_type=='Home'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon>
          <v-icon v-b-tooltip.hover title="Livraison gratuit" v-if="props.row.free_delivered==true" style="margin-left:10px;margin-right:10px;" color="primary">mdi-currency-usd-off</v-icon>
          <!-- <v-icon v-if="props.row.delivery_type=='Stop Desk'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon> -->
          <v-btn id="pay-btn" v-if="props.row.status == 'Livrée' && props.row.posttoprovider == false && props.row.free_delivered == false && props.row.price != 0" class="float-right" depressed small color="red">NOT PAID</v-btn>
          <v-btn v-if="props.row.status == 'Livrée' && props.row.posttoprovider == true && props.row.free_delivered == false && props.row.price != 0" depressed small color="green" class="float-right">GET PAID</v-btn>
          <!-- <v-btn v-if="props.row. == 'Livrée' && props.row.posttoprovider == true" depressed small color="green">GET PAID</v-btn> -->
        </span>
        <span v-else-if="props.column.field == 'depot1'">
          <span>{{props.row.depot1.name}}</span>
        </span>
        <span v-else-if="props.column.field == 'depot2'">
          <span>{{props.row.depot2.name}}</span>
        </span>
        <span v-else-if="props.column.field == 'content'">
          <span>{{props.row.colis_numbers}}/{{props.row.quantity}}/{{props.row.weight}}</span>
        </span>
        <span v-else-if="props.column.field == 'price'">
          <span>{{props.row.price}}</span>
          <v-icon v-if="props.row.changable == true" id="changable-btn" v-b-tooltip.hover title="Cliquer pour changer" style="margin-left:10px;margin-right:10px;" color="primary">mdi-cog</v-icon>
          <!-- <b-form-input v-else id="changable-input" type="number" v-model="props.row.price" @keydown.native="makeChanges"></b-form-input> -->
        </span>
        <span v-else>{{props.row[props.column.field]}}</span>
      </template>
    </vue-good-table>
  </base-material-card>

</template>

<script>
/* eslint-disable */
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Dashboard',
  data () {
    return {
      // searchTerm: '',
      moment: moment,
      copyMessage: 'Cliquer pour copier',
      provider: null,
      pemail: null,
      fuckoff: null,
      posts: [],
      welcoming: null,
      packages: [],
      status_list: ['Created', 'Livrée','All'],
      wilaya_list: ['Alger', 'Annaba', 'Bejaia','All','Mascara'],
      produit_list: ['Phone', 'Ibad', 'Laptop','All'],
      status_search: 'All',
      wilaya_search: 'All',
      produit_search: 'All',
      search: null,
      packageDetail: {provider:{},selected_driver:{}},
      columns: [
        {
          label:'Tracking_Id',
          field:'tracking_id',
          filterOptions: {enabled:true,placeholder: 'Entrer le tracking',}
        },
        {
          label:'Marchandise',
          field:'content',
          filterOptions: {enabled:true,placeholder: 'Entrer Produit',}
        },
        {
          label:'Nom de l\'expéditeur',
          field:'client_name',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Nom de l\'entreprise',
          field:'provider_name',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Status',
          field:'status',
          width: '180px',
          filterOptions: {enabled:true,placeholder: 'Choisir le status',filterDropdownItems:['Created','Livrée','EnRoute','Arrived','Chargement','Returned','Returned-ARV','Returned-DLI','Returned-ONGO']}
        },
        {
          label:'Wilaya',
          field:'from_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Wilaya d\'arrivé',
          field:'to_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Prix',
          width: '120px',
          field:'price'
        }
      ],
      columns2: [
        {
          label:'Tracking_Id',
          field:'tracking_id',
          filterOptions: {enabled:true,placeholder: 'Entrer le tracking',}
        },
        {
          label:'Colis/Qty/Poids',
          field:'content',
          filterOptions: {enabled:true,placeholder: 'Entrer Produit',}
        },
        {
          label:'Nom de chauffeur',
          field:'driver_name',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Status',
          field:'status',
          filterOptions: {enabled:true,placeholder: 'Choisir le status',filterDropdownItems:['Created','Livrée','EnRoute','Arrived','Chargement','Returned','Returned-ARV','Returned-DLI','Returned-ONGO']}
        },
        {
          label:'Depot de départ',
          field:'depot1',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Depot d\'arrivé',
          field:'depot2',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
      ],
      rows2: [],
      rows: [],
      willayas: [],
      show: false,
      showChangable: false,
      showChangable2: false,
      infoshow: [],
      show2 : false,
      show3: false,
      tr_id2: null,
      delivered: null,
      returned: null,
      created: null,
      engoing: null,
      enhold: null,
      setset: null,
      drivers: [],
      drivers2: [],
      vehicules: ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN'],
      isLoading: false,
      hostName: null,
      showDocModal: false,
      queryStatus: null
    }

  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.hostName = process.env.VUE_APP_MEDIA_URL.replace('/media/','')
    this.queryStatus = this.$route.fullPath.split('?status=')[1]
    if (this.queryStatus != null && this.queryStatus != undefined) {
      this.columns[4].filterOptions.filterValue = this.queryStatus.replace('%C3%A9','é')
      console.log(this.columns[4].filterOptions)
    }
    this.gettingwilayas()
    this.getPostCenters()
    this.getingpackages()
    this.getStatusList()
    this.getDeliveryExpress()
    this.loadDrivers()
  },
  methods: {
    reloadItems() {
      this.isLoading = true
      this.getingpackages()
      this.getDeliveryExpress()
    },
    loadDrivers() {
      axios.post('admin-drivers', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success') {
          this.drivers = [];
          this.drivers2 = []
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].approved == true) {
              response.data.data[i].id = response.data.data[i].did;
              response.data.data[i].name = response.data.data[i].name + " => " + response.data.data[i].status
              if (response.data.data[i].service_type == "Service Logistique" || response.data.data[i].service_type == "Logistique/Express") this.drivers.push(response.data.data[i])
              if (response.data.data[i].service_type == "Service Express" || response.data.data[i].service_type == "Logistique/Express") this.drivers2.push(response.data.data[i])
            }
          }
          this.drivers.push({
            name:'Autre',
            code:'other',
            did: -1,
            available:null
          })
          this.drivers2.push({
            name:'Autre',
            code:'other',
            did: -1,
            available:null
          })
        }
      })
    },
    getPostCenters() {
        axios.post('get-post-centers').then(response => {
          if (response.data.status == 'success') {
            let names= []
            response.data.data.forEach(element=> {
              names.push(element.name)
            })
            this.$set(this.columns[5].filterOptions, 'filterDropdownItems', names);
            this.$set(this.columns[4].filterOptions, 'filterDropdownItems', ['Created','Confirmé']);
          }
        })
      },
    getpaid(){
      axios.post('getpaid',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.tr_id2,
        setset: this.setset,
      }).then(response => {
        this.getingpackages()
      })

    },
    copyTrackingId(row) {
      this.$clipboard(row.tracking_id);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    deletePackage() {
      axios.post('deletecolis',{
          auth_token: localStorage.getItem('auth_token'),
          tr_id: this.packageDetail.tracking_id,
        }).then(response => {
          this.getingpackages()
        })
    },
    makeChanges(status) {
      axios.post('provider-change-delivery',{
        auth_token: localStorage.getItem('auth_token'),
        status:status,
        tr_id: this.packageDetail.tracking_id,
        client_name:this.packageDetail.client_name,
        client_phone:this.packageDetail.client_phone,
        receiver_name:this.packageDetail.receiver_name,
        receiver_phone:this.packageDetail.receiver_phone,
        content:this.packageDetail.content,
        weight:this.packageDetail.weight,
        delivery_type:this.packageDetail.delivery_type,
        description:this.packageDetail.description,
        price:this.packageDetail.price,
        payment_method:this.packageDetail.payment_method,
        fees:this.packageDetail.fees,
        from_wilaya:this.packageDetail.from_wilaya,
        to_wilaya:this.packageDetail.to_wilaya,
        address1:this.packageDetail.address1,
        address2:this.packageDetail.address2,
        driver_name: this.packageDetail.driver_name,
        driver_phone: this.packageDetail.driver_phone,
        driver_matricule: this.packageDetail.driver_matricule,
        selected_driver:this.packageDetail.selected_driver,
      }).then(response => {
        if (response.data.status == 'success')
          this.getingpackages()
          this.showChangable = false
      })
    },
    makeChanges2(status) {
      axios.post('change-delivery-express',{
        auth_token: localStorage.getItem('auth_token'),
        status:status,
        tr_id: this.packageDetail.tracking_id,
        selected_driver:this.packageDetail.selected_driver,
        client_name:this.packageDetail.client_name,
        client_phone:this.packageDetail.client_phone,
        receiver_name:this.packageDetail.receiver_name,
        receiver_phone:this.packageDetail.receiver_phone,
        content:this.packageDetail.content,
        weight:this.packageDetail.weight,
        delivery_type:this.packageDetail.delivery_type,
        description:this.packageDetail.description,
        price:this.packageDetail.price,
        payment_method:this.packageDetail.payment_method,
        fees:this.packageDetail.fees,
        from_wilaya:this.packageDetail.from_wilaya,
        to_wilaya:this.packageDetail.to_wilaya,
        address1:this.packageDetail.address1,
        address2:this.packageDetail.address2,
        driver_name: this.packageDetail.driver_name,
        driver_phone: this.packageDetail.driver_phone,
        driver_matricule: this.packageDetail.driver_matricule
      }).then(response => {
        if (response.data.status == 'success')
          this.getDeliveryExpress()
          this.showChangable2 = false
      })
    },
    RowClick2(params) {
      this.packageDetail = params.row
      if (params.event.target.id == 'status-btn2' || params.event.target.parentNode.id == 'status-btn2' || params.event.target.parentNode.parentNode.id == 'status-btn2' || params.event.target.parentNode.parentNode.parentNode.id == 'status-btn2') {
        this.showChangable2 = true
      } else if (params.event.target.id == "history-btn"){
        this.show3 = true
      } else if (params.event.target.id == 'tracking-id-btn' || params.event.target.parentNode.id == 'tracking-id-btn') {
        this.copyTrackingId(params.row)
      } else{
        this.show = true
        this.infoshow = params.row
      }
    },
    RowClick(params){
      this.packageDetail = params.row
      for (let i in this.drivers) {
        if (params.row.selected_driver == this.drivers[i].id) {
          this.packageDetail.selected_driver = this.drivers[i];
          break
        }
      }
      if (params.event.target.id == "history-btn"){
        this.show3 = true
      }
      else if (params.event.target.id == "doc-btn") {
        this.showDocModal=true;
        this.packageDetail=params.row
      }
      else if (params.event.target.id == 'print-btn' || params.event.target.parentNode.id == 'print-btn') {
        axios.post('print-pdf', {
          auth_token: localStorage.getItem('auth_token'),
          tracking_id: this.packageDetail.tracking_id
        }).then(response=> {
          if (response.data.status == 'success') {
            const link = document.createElement('a')
            link.target = '_blank'
            link.href = process.env.VUE_APP_MEDIA_URL+"document.pdf"
            link.setAttribute('download', "document.pdf")
            document.body.appendChild(link)
            link.click()
          }
        })
      }
      else if (params.event.target.id == 'status-btn' || params.event.target.parentNode.id == 'status-btn' || params.event.target.parentNode.parentNode.id == 'status-btn' || params.event.target.parentNode.parentNode.parentNode.id == 'status-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'changable-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'tracking-id-btn' || params.event.target.parentNode.id == 'tracking-id-btn') {
        this.copyTrackingId(params.row)
      }
      else if (params.event.target.id == "pay-btn" || params.event.target.parentNode.id == 'pay-btn'){
        this.show2 = true
        this.tr_id2 = params.row.tracking_id
        this.setset = 'normal'
      }
      else{
        this.show = true
        this.infoshow = params.row
      }
    },
    gettingwilayas(){
      axios.post('get-wilayas').then(response => {
        this.willayas = response.data.data
        // for (let i=0;i<response.data.data.length;i++){
        //   this.willayas = response.data.data
        // }
        // this.columns[4].filterOptions.filterDropdownItems= this.willayas
        this.$set(this.columns[5].filterOptions, 'filterDropdownItems', this.willayas);
        this.$set(this.columns[4].filterOptions, 'filterDropdownItems', ['Created','Confirmé']);
      })

    },
    filtering(params) {
      console.log(params)
    },
    getStatusList() {
      axios.post('get-status-list').then(response => {
        if (response.data.status == 'success') {
          this.items = response.data.data
        }
      })
    },
    handleModalHide(params) {
      this.show = false
      this.showChangable = false
    },
    hideModal(params) {
      this.show = false
      this.showChangable = false
    },
    serialiseDate(date) {
      if (date != null && date!= undefined)
        return moment(date).format('DD/MM/YYYY HH:mm')
      else
        return 'N/A'
    },
   detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider.name
          this.pemail = response.data.data.provider.email
          this.fuckoff = response.data.status
          for (let i=0;i<response.data.data.posts.length;i++){
            this.posts.push({code:response.data.data.posts[i].code,name:response.data.data.posts[i].name})
          }
        })
      },
    getingpackages(){
      axios.post('get-deliveries',{
        auth_token: localStorage.getItem('auth_token'),
        status_type:'request',
        provider:false
      }).then(response => {
        this.rows=response.data.data.packages
        this.infoshow=response.data.data.packages
        this.delivered=response.data.data.Livrée
        this.returned=response.data.data.Returned
        this.created=response.data.data.Created
        this.engoing=response.data.data.EnRoute
        this.enhold=response.data.data.Chargement
      })
    },
    getDeliveryExpress(){
      axios.post('get-delivery-express',{
        auth_token: localStorage.getItem('auth_token'),
        status_type:'request',
      }).then(response => {
        this.rows2=response.data.data.packages
      })
    }
  },
}
</script>
<style>
.color-red {
  background-color:red;
  color:white;
}
.color-green {
  background-color:green;
  color:white;
}
.multiselect__option--selected {
  background: greenyellow !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>